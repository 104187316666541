import { MutationFunction } from '@tanstack/react-query';
import api from './api';

export const createCredentialsLink: MutationFunction<
  unknown,
  { text: string; ticketNumber: string }
> = async ({ text, ticketNumber }) => {
  await api.post('/internal/credentials-link', {
    text,
    ticketNumber,
  });
};

export const openCredentialsLink: MutationFunction<
  string,
  { linkId: string; salt: string }
> = async ({ linkId, salt }) => {
  const data = await api.post<{ text: string }>(`/link/${linkId}`, {
    salt,
  });

  return data.data.text;
};
