import type { NextPage } from 'next';
import Button from '@mui/material/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Box, TextField } from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { createCredentialsLink } from '../http/credentials';
import toast from 'react-hot-toast';

interface FormInputs {
  ticketNumber: string;
  messageText: string;
}

const schema = yup.object({
  ticketNumber: yup.string().required('El número de ticket es obligatorio'),
  messageText: yup.string().required('El texto es obligatorio'),
});

const Home: NextPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  // Define the mutation function
  const mutation = useMutation({
    mutationFn: createCredentialsLink,
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    await mutation.mutateAsync({
      text: data.messageText,
      ticketNumber: data.ticketNumber,
    });

    toast.success('Formulario enviado exitosamente');
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ mt: 2 }}>
      <TextField
        label="Número de ticket de JIRA"
        variant="outlined"
        fullWidth
        margin="normal"
        {...register('ticketNumber')}
        error={Boolean(errors.ticketNumber)}
        helperText={errors.ticketNumber?.message}
      />
      <TextField
        label="Texto a enviar"
        variant="outlined"
        fullWidth
        margin="normal"
        multiline
        rows={4}
        {...register('messageText')}
        error={Boolean(errors.messageText)}
        helperText={errors.messageText?.message}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ mt: 2 }}
        disabled={mutation.isPending}
      >
        {mutation.isPending ? 'Enviando...' : 'Enviar'}
      </Button>
      {mutation.isError && (
        <Box sx={{ mt: 2, color: 'error.main' }}>
          Ha ocurrido un error al enviar el formulario.
        </Box>
      )}
      {mutation.isSuccess && (
        <Box sx={{ mt: 2, color: 'success.main' }}>
          Formulario enviado exitosamente.
        </Box>
      )}
    </Box>
  );
};

export default Home;
